/* src/productlist.css */

.product-list {
    padding: 16px;
    background-color: #333; 
    color: #fff; 
}

.product-list h3 {
    text-align: center;
    margin: 20px 0px;
    font-size: 1.5rem;
    color: #e74c3c;

}

.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

.product-card {
    border: 1px solid #444; /* Darker border to match the background */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* Darker shadow */
    transition: transform 0.2s;
    background-color: #222; /* Darker background for cards */
}

.product-card:hover {
    transform: translateY(-5px);
}

.product-image img {
    width: 100%;
    height: 200px; /* Set a fixed height for all images */
    object-fit: cover; /* Ensure images cover the area */
}

.product-details {
    padding: 16px;
    text-align: center;
}

.product-details h2 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
}

.product-details .new-price {
    font-size: 1.5rem;
    color: #e74c3c;
    margin: 0.5rem 0;
}

.product-details button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.product-details button:hover {
    background-color: #218838;
}

@media (max-width: 1200px) {
    .product-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .product-grid {
        grid-template-columns: 1fr;
    }
}

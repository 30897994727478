.additional-services {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #1a1a1a ;
    color: #fff;
    padding: 100px;
}

.content {
    max-width: 50%;
}

.title {
  margin-bottom: 40px;
  font-size: 700;
  font-size: 38px;
  line-height: 48px;
  font-family: Rubik, sans-serif;
}

.description, .list{
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 32px;
  font-weight: 400;
}

 .icon {
    margin-right: 10px; 
}

.know-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #cb4154; 
    border: none;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}

.image {
    max-width: 50%;
    height: 100vh;
}

.image img {
    width: 100%;
    height: auto;
    display: block;

}

@media (max-width: 768px) {
    .additional-services {
        flex-direction: column;
        padding: 0;
    }
    #service{
        margin-top: 50px;
    }
    .description, .list{
        font-family: Rubik, sans-serif;
        font-size: 17px;
    font-weight: 400;
    line-height: 32px;
      }

    .content, .image {
        max-width: 100%;
        padding: 0 20px;
    }

    .title {
        font-size: 32px; 
    }

    .image {
        margin-top: 50px;
    }

    .icon {
        margin-right: 15px; 
    }

    .know-more-btn {
        margin-top: 15px; 
        padding: 8px 16px; 
        font-size: 0.9rem; 
    }
}
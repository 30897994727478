.faq-section p,
.accordion {
    width: 85%;
    height: 60vh;
    font-family: karla,sans-serif;
}

.accordion .accordion-button:focus,
.accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) {
    background-color:  #131d3b;
    color: #cb4154;
}

.accordion .accordion-button:not(.collapsed)::after {
    filter: invert();
}
.accordion-item {
    overflow: hidden; 
    transition: height 0.2s ease-out;
}

.faq-section {
    overflow: hidden; 
    background: rgb(247, 246, 246);
}
.titlee{
    margin-top: 40px;
    padding-top: 40px;
    font-size: 17px;
    line-height: 21px;
    font-family: Rubik,sans-serif;
    font-weight: 700;
    margin: 20px 0px;
    color: #cb4154;
}

@media only screen and (max-width: 992px) {
    .faq-section p,
    .accordion {
        width: 100%;
    }
}
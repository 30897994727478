.header {
    background-image: url('../../images/homee.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: white;
    background-color: rgba(228, 220, 220, 0.8);
    position: relative;
    overflow: hidden;

}

.top-section, .middle-section, .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.top-section {
    padding: 10px;
    border-bottom: 1px solid  ;
}
.top-section, .middle-section{
    font-family: karla, sans-serif;
    font-weight: 600;
    line-height: 32px;
    size: 17px;

}

.phone-info, .address-info, .social-icons {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
}
    
.phone-info {
    justify-content: flex-start;
}

.address-info {
    justify-content: center;
    margin-right: 20px;
    word-wrap: normal;
    flex-wrap: nowrap;
}

.social-icons {
    justify-content: flex-end;
    gap: 30px;

}

.middle-section {
    padding: 10px;
}

.bottom-section {
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
        text-align: left; 
        padding: 80px 0px 40px 0px; 
        margin-top: 60px;
        position: relative;
    }
    .smaller-screen.bottom-section
    {
        padding: 100px 0px 40px 0px;
        position: relative;
    }
.bottom-section h1{
    font-family: rubik,sans-serif;
    font-size: 57px;
    font-weight: 700;
    color: white;
}
.bottom-section p{
    font-family: rubik,sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: white;
    margin-top: 10px;
}
.smaller-screen .bottom-section h1 {
    font-size: 36px;
    line-height: 50px;

}
.smaller-screen .bottom-section p{
    font-size: 14px;
    line-height: 18px;
}
#small-screen-btn{
    margin-top: 30px;
}

.nav-link {
    font-size: 1.2rem;
    color: white;
    opacity: 0.8;
}
.nav-link:hover{
    color: #a83244 !important;

}
.fab {
    font-size: 1.5rem;
}

.fab:hover {
    color: #dc3545;
}

.navbar-toggler {
    color: white;
    border: none;
    background: #f80828;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler-icon {
    color: white;
}

/* Navbar contents */
.navbar-collapse {
    
    padding: 1rem;
}

.smaller-screen .nav {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    margin-top: 0px;
}
.smaller-screen .nav-link:hover{
    color: #a83244 !important;

}

.smaller-screen .nav-item {
    flex: 1;
    text-align: center;
    margin: 5px 0;
    padding: 10px 0;
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    justify-content: flex-start;
    text-align: left;
    
}
.smaller-screen .nav-link{
    text-align: left;
    color: black !important;

}

.contact-info {
    display: flex;
    gap: 20px;
    color: #fff;
}

.social-icons a {
    color:white;
   
}

.social-icons a:hover {
    color: #cb4154;
}
.social-iconss a {
    color:black !important;
    gap: 20px;
    margin-right: 20px;
    padding: 5px;
   
}

.social-iconss a:hover {
    color: #cb4154;
}

.custom-btn, .custom-btnn {
    background-color: #f80828;
    border-color: #cb4154;
    color: white;
    height: 50px;
    width: 150px;
    margin-top: 5px;
    padding: 20px;
    font-family: Karla, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
}
.custom-btnn{
    height: 50px;
    width: 200px;
}
.custom-btnn:hover{
    background-color: #cb4154; 
    border-color: #a83244;
    color: white; 
}

.custom-btn:hover {
    background-color: #cb4154; 
    border-color: #a83244;
    color: white;
}
.collapse {
    background-color: white; 
}

.navbar {
    background-color: white; 
    width: 100%;
}

.navbar.collapse.show {
    background-color: white !important; 
}
.flex{
    justify-content: flex-start;
    align-items: flex-start;
    text-align: right;
}
.dropdown-menu {
    padding: 20px;
    background-color: #002e5b; /* Same background as header */
    display: none;
    height: 40vh;
    width: 800px;
    
}
.dropdown-menu a{
    color: white;
}
.dropdown-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.dropdown-menu.show {
    display: block;
}

@media (max-width: 768px){
    .header{
        height: 100vh;
    }
    .dropdown-menu{
        background-color: white;
        height: 80vh;
        width: 300px;
        margin-left: 10px;
        font-size: 1em;
        line-height: 27px;
        

    }
    .dropdown-menu a{
        color: #333;
        font-size: 1.2em;
        line-height: 31px;
    }
    .dropdown-menu a:hover{
        color: #a83244;
        background-color: white;
    }
    .dropdown-item  {
        border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    }
    
}
.resin-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    font-family: karla, sans-serif;
}

.resin-item img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }

.resin-item h2 {
    margin: 15px 0 10px;
}
.resin-item p{

    text-align: justify;
}

@media (min-width: 768px) {
    .resin-item {
        flex-direction: row;
        text-align: left;
    }

    .resin-item img {
        max-width: 300px;
        margin-right: 20px;
    }
}

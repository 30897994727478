.headerr {
    background: #002e5b;
    color: white;
    /* position: relative;
    z-index: 10;  */
}

.dropdown-menu {
    padding: 20px;
    background-color: #002e5b; /* Same background as header */
    display: none;
    height: 40vh;
    width: 800px;
    
}
.dropdown-menu a{
    color: white;
}


.dropdown-menu.show {
    display: block;
}

.middle-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: karla, sans-serif;
    font-weight: 600;
    line-height: 32px;
    size: 17px;
    padding: 10px;
}

#small-screen-btn{
    margin-top: 30px;
}

.nav-link {
    font-size: 1.2rem;
    color: white;
    opacity: 0.8;
}
.nav-link:hover{
    color: #a83244 !important;

}
.fab {
    font-size: 1.5rem;
}

.fab:hover {
    color: #dc3545;
}

.navbar-toggler {
    color: white;
    border: none;
    background: #f80828;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler-icon {
    color: white;
}

/* Navbar contents */
.navbar-collapse {
    
    padding: 1rem;
}

.smaller-screen .nav {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    margin-top: 0px;
}
.smaller-screen .nav-link:hover{
    color: #a83244 !important;

}

.smaller-screen .nav-item {
    flex: 1;
    text-align: center;
    margin: 5px 0;
    padding: 10px 0;
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    justify-content: flex-start;
    text-align: left;
    
}
.smaller-screen .nav-link{
    text-align: left;
    color: black !important;

}

.contact-info {
    display: flex;
    gap: 20px;
    color: #fff;
}


.custom-btn, .custom-btnn {
    background-color: #f80828;
    border-color: #cb4154;
    color: white;
    height: 50px;
    width: 150px;
    margin-top: 5px;
    padding: 20px;
    font-family: Karla, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
}
.custom-btnn{
    height: 50px;
    width: 200px;
}
.custom-btnn:hover{
    background-color: #cb4154; 
    border-color: #a83244;
    color: white; 
}

.custom-btn:hover {
    background-color: #cb4154; 
    border-color: #a83244;
    color: white;
}
.collapse {
    background-color: white; 
}

.navbar {
    background-color: white; 
    width: 100%;
}

.navbar.collapse.show {
    background-color: white !important; 
}
.flex{
    justify-content: flex-start;
    align-items: flex-start;
    text-align: right;
}
.dropdown-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.dropdown-item {
    color: white;
    padding: 10px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 5px;
}

.dropdown-item:hover {
    background-color: #333;
    color: white;
}

@media (max-width: 768px){
    .header{
        height: 100vh;
    }
    .dropdown-menu{
        background-color: white;
        height: 80vh;
        width: 300px;
        margin-left: 10px;
        font-size: 1em;
        line-height: 27px;

    }
    .dropdown-menu a{
        color: #333;
        font-size: 1.2em;
        line-height: 31px;
    }
    .dropdown-menu a:hover{
        color: #a83244;
        background-color: white;
    }
    .dropdown-item  {
        border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    }
}
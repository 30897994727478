.stats {
    margin-top: 20px;
    position: relative;
    top: 120px;
    font-family: Rubik,sans-serif;
    
  }
  
  .stat-box {
    margin: 0px;
    height: 170px;
  }
  .stat-box h2,p{
    justify-content: center;
    text-align: center;
    
  }
  .stat-box h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    padding-top: 20px;
  }
  
  .stat-box p {
    font-size: 17px;
    line-height: 32px;
    font-weight: 400;
  }.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .row.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
  

  .mainbody {
    background-image: url('/src/images/homee.jpeg');
    background-size: cover;
    background-position: center;
    height: 400px;
    color: white;
    padding: 50px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.welcome-message,
.soughtout-message {
    background-color:rgb (147, 147, 147, 0.5);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    font-size: 2rem; 
    font-weight: bold; 
    opacity: 0.6;
    animation: fadeIn 1s forwards;
   
}
.welcome-message {
    margin-top: 40px; 
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}



.clients-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
}

.clients-text {
    max-width: 800px;
    text-align: center;
    margin-bottom: 20px;
}

.clients-text h2 {
    font-size: 17px;
    line-height: 21px;
    font-family: Rubik,sans-serif;
    font-weight: 700;
    margin: 20px 0px;
    color: #cb4154;
}

.clients-text p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.clients-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 800px;
}

.client-logo img {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: contain;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .clients-text h2 {
        font-size: 1.5rem;
    }

    .clients-text p {
        font-size: 0.9rem;
    }
}

.footer {
	padding: 40px 0;
	background: #131d3b;
	font-family: Karla, sans-serif;
	font-weight: 400;
	font-size: 17px;
	line-height: 25px;
  }
  
  .font-size-700 {
	font-weight: 700;
	font-size: 24px;
  }
  .footer .last-child {
	text-align: justify;
	font-family: Karla, sans-serif;
	font-weight: 400;
	font-size: 17px;
	line-height: 25px;
  }
  
  .footer h5 {
	margin-bottom: 20px;
  }
  
  .footer p {
	font-size: 14px;
	color: #ddd;
  }
  
  .footer ul {
	list-style: none;
	padding: 0;
  }
  
  .footer ul li {
	margin-bottom: 10px;
  }
  
  .footer ul li a {
	color: #ddd;
	text-decoration: none;
  }
  
  .footer ul li a:hover {
	text-decoration: underline;
  }
  
  .footer .social-icoons {
	margin-top: 20px;
	

  }
  
  .footer .social-icoons a {
	color: #ddd;
	margin-right: 10px;
	font-size: 16px;
	color: #cb4154;
	/* margin-right:10px ; */
  }
  
  .footer .social-icoons a:hover {
	color: #cb4154;
  }
  
  .footer-bottom {
	border-top: 1px solid #444;
	padding-top: 20px;
	margin-top: 20px;
	text-align: center;
  }
  
  .footer-bottom p {
	margin: 5px 0;
  }
  
  .paragraph1 {
	justify-content: center;
  }
	

  
  @media (max-width: 767px) {
	.footer .col-12 {
	  margin-bottom: 20px;
	}
	.footer .last-child {
	  margin-bottom: 0;
	  text-align: center;
	  justify-content: center;
	}
	.footer .row {
	  text-align: center;
	}

	.paragraph1 {
	  margin-bottom: 10px;
	}
  }
  
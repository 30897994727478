.reasons {
  padding: 40px 0;
  background: #f9f9f9;
  text-align: center;
}

.reasons .title {
  color: #131d3b;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
}

.reason-box {
  background: #fff;
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  height: 100%; 
}

.reason-box:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.reason-box i {
  font-size: 40px;
  color: #131d3b;
  margin-bottom: 10px;
}

.reason-box h3, .reason-box h4 {
  color: #cb4154;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center; 
}
#why{
  color: #cb4154;
}

.reason-box p {
  color: #666;
  font-size: 14px;
  text-align: center; 
}

@media (max-width: 767px) {
  .reasons .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch; 
  }
}


@media (max-width: 576px) {
  .reason-box {
      padding: 15px;
      justify-content: center;
      align-items: center;
  }

  .reason-box i {
      font-size: 35px;
  }

  .reason-box h3 {
      font-size: 20px;
  }

  .reason-box p {
      font-size: 13px;
  }
}
#contacts-head {
    font-size: 17px;
    line-height: 21px;
    font-weight: 700;
    color:#cb4154 !important ;
}
#contacts-subhead {
  font-family: Rubik, sans-serif;
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
  color:#131d3b !important ;
}
#address {
  margin: 10px 0px;
  padding: 10px 0px;
}


.address {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
}

form .form-group {
  margin-bottom: 1.5rem;
}

form .form-label {
  margin-top: 1rem;
}

button {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  #contact {
    padding: 10px;
  }

  .address, .bg-light {
    padding: 10px;
  }
}

.about-container {
  margin: 0 auto; 
  padding: 0 15px; 
  
}
.about-company {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  margin: 0px 50px;
}
.image-section {
  position: relative;
  flex: 1;
}
.main-image {
  width: 80%;
  height: auto;
}
.overlay-image {
  position: absolute;
  bottom: -100px;
  left: 250px;
  width: 60%;
  height: 350px;
}
.content-section {
  flex: 1;
  padding: 40px;
  margin-left: 20px;
  margin-top: 90px;
}
.content-section p {
  margin: 20px 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}
.certified {
  margin: 20px 0;
  font-size: 1rem;
  color: #666;
  display: flex;
  align-items: center;
}
.certified span {
  margin-left: 10px;
}
.read-more-btn {
  padding: 10px 20px;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 40px;

}
.read-more-btn:hover{
  background-color: #680f1b; 
  border-color: #a83244;
  color: white;
}
.content-section h1{
  font-family: rubik,sans-serif;
  font-size: 38px;
  line-height: 49px;
  font-weight: 700;
  color: #011536 !important;
}
.content-section h2{
  font-family: rubik,sans-serif;
  font-size: 17px;
  line-height: 21px;
  font-weight: 700;
  color:#cb4154 ;
}
#content{
  font-family: karla,sans-serif;
  font-size: 17px;
  line-height: 32px;
  font-weight: 400;
  color: #171717;
  text-align: justify;
}
.read-more-btn{
  background-color: #c93f52;
}
@media (max-width: 1250px){
  .about-company{
    margin-top: 20px;
  }
  .main-image{
    width: 90%;
    height: 400px;
  }
  .overlay-image {
    position: absolute;
    bottom: -200px;
    left: 80px;
    width: 80%;
    height: 300px;
  }
}

@media (max-width: 900px) {
  .about-company {
    flex-direction: column;
    align-items: center;
  }
  .image-section {
    position: relative;
    text-align: center;
    margin-top: 40px;
  }
  .main-image,
  .overlay-image {
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .overlay-image {
    position: static;
    margin-top: 10px;
  }
  .content-section {
    text-align: center;
    padding: 20px 5px;
    margin-left: 0;
    margin-top: 20px;
  }
  .content-section h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .about-company {
    flex-direction: column;
    align-items: center;
  }
  .about-container {
    margin: 0 auto; 
    padding:0px; 
  }
  .about-company {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px 0px;
    background-color: #f9f9f9;
    margin: 0px ;
  }
  .overlay-image {
    width: 70%;
    margin-top: -80px;
    margin-left: 180px;
  }
  .content-section {
    text-align: center;
    padding: 20px 5px;
  }
  .content-section h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .about-company {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }
  .image-section {
      position: relative;
      text-align: center;
      margin-top: 40px;
  }
  .overlay-image {
      position: static;
      margin-top: 10px; 
      width: 80%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
  }
  .content-section {
      margin-left: 0;
      margin-top: 20px;
  }
}
.services {
    background-color: #1a1a1a;
    padding: 40px 20px;
    text-align: center;
    font-family: Rubik,sans-serif;
    color: white;
  }
  #top{
    margin-top: 130px;

  }
  #service {
    font-size: 17px;
    line-height: 21px;
    font-weight: 700;
    color:#cb4154 ;
  }
  .services-title {
    margin-bottom: 40px;
    font-size: 700;
    font-size: 38px;
    line-height: 48px;
    
  }
  
  .services-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .service {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 20px;
    margin: 0px;
    width: 35%;
    box-sizing: border-box;
    text-align: left;
  }

  #paragraph1, #paragraph2, #paragraph3{
    font-size: 17px;
    font-weight: 400;
    line-height: 32px;
  }
  
  .service img {
    width: 100%;
    height: 300px;
    border-radius: 8px;
  }
  
  .service h3 {
    font-size: 1.5rem;
    margin-top: 10px;
    color: white;
  } 
  
  
  .service a {
    color:#cb4154 !important;
    text-decoration: none;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .services-list {
      flex-direction: column;
      align-items: flex-start; 
  }
  .services-title {
    margin-bottom: 40px;
    font-size: 700;
    font-size: 32px;
    line-height: 42px;
    
  }

  .service {
      width: 100%;
      margin: 10px 0;
      padding: 15px;
      text-align: left; 
  }
  .services{
    margin: 0px;
    padding: 0px;
  }
  #service{
    margin-top: 30px;
    padding-top: 50px;
  }
  }
  